import http from "../http";
import Service from "../../Service";

/**
 * @type ProductService
 */
export default class ProductService extends Service {

    list(params, token) {
		return http.get(`/admin/management/products`, {
            cancelToken: token,
			headers: this.getHeaders(),
            params
		});
	}

    delete(id,pattern) {
		return http.delete(`/admin/management/products/${id}`, {
            headers: this.getHeaders(),
			params:{'pattern' : pattern}
		});
	}

    highlight(id, is_highlighted) {
		return http.patch(`/admin/management/products/${id}/highlight`, { is_highlighted }, {
            headers: this.getHeaders()
		});
	}

	getProduct(id, init, end, page) {
		return http.get(`/admin/management/products/${id}?dataInicio=${init}&dataFim=${end}&page=${page}`, {
            headers: this.getHeaders()
		});
	}

    create(product) {
		return http.post(`/admin/management/products`, product, {
            headers: this.getHeaders()
		});
	}

    add_brand(id, brand, pattern, tblPadrao = 0) {
		return http.post(`/admin/management/products/${id}/brands?tbl_padrao=${tblPadrao}`, { name: brand, pattern: pattern }, {
            headers: this.getHeaders()
		});
	}

    update(id, product) {
		return http.put(`/admin/management/products/${id}`, product, {
            headers: this.getHeaders()
		});
	}

    save(product) {
        return product.id ? this.update(product.id, product) : this.create(product)
    }

	getBrands(productId){
		return http.get(`/admin/${productId}/group`, {
            headers: this.getHeaders()
		})
	}
	getSupliers (pro_padrao_id, page, city, state) {
        let url = `/admin/management/products/${pro_padrao_id}/providers?page=${page}`
        if (city.cid_id) url += `&cid_id=${city.cid_id}`
        if (state.est_id) url += `&est_id=${state.est_id}`
		return http.get(url, {headers: this.getHeaders()})
	}
	getPrice (pro_padrao_id) {
		return http.get(`/admin/management/products/${pro_padrao_id}/group`, {headers: this.getHeaders()})
	}
	getCod () {
		return http.get(`/admin/management/products/sequence/ean`, {headers: this.getHeaders()})
	}
	getPriceBrand (payload) {
        let url = `/admin/management/products/${payload.pro_padrao_id}/group?`
        if (payload.city) url = url + 'city=' + payload.city + '&'
        if (payload.fu) url = url + 'fu=' + payload.fu
		return http.get( url , {headers: this.getHeaders()})
	}

	updateBrandName(id, product){
		return http.put(`/admin/management/products/name/${id}`, product, {
            headers: this.getHeaders()
		});
	}

}
